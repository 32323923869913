import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Group, Text } from '@mantine/core';

import { HolidayVariation } from '@interfaces/holidays.interface';

import {
  selectHoliday,
  selectHolidayVariation,
  setHolidayVariation,
} from '@slices/checkout.slice';

interface HolidayVariationSelectorProps {
  onVariationChosen: () => void;
}

export default function HolidayVariationSelector({
  onVariationChosen,
}: HolidayVariationSelectorProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const holiday = useSelector(selectHoliday);
  const holidayVariation = useSelector(selectHolidayVariation);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleVariationClick = (variation: HolidayVariation) => {
    dispatch(setHolidayVariation(variation));
    onVariationChosen();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    holiday && (
      <>
        <Text>Seleziona data</Text>
        <Group>
          {holiday.variations.map((v) => (
            <Button
              key={v.id}
              variant={v === holidayVariation ? 'filled' : 'outline'}
              disabled={
                v.lastOnlineBookableDate
                  ? new Date(v.lastOnlineBookableDate) < new Date()
                  : false
              }
              onClick={() => handleVariationClick(v)}
            >
              {new Intl.DateTimeFormat(i18n.language).format(new Date(v.date))}
            </Button>
          ))}
        </Group>
      </>
    )
  );
}
