import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionIcon,
  Button,
  Group,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';

import { selectPartecipants, setPartecipants } from '@slices/checkout.slice';

interface PartecipantSelectorProps {
  onPartecipantChosen: () => void;
}

export default function PartecipantsSelector({
  onPartecipantChosen,
}: PartecipantSelectorProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const { i18n } = useTranslation();

  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const partecipants = useSelector(selectPartecipants);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants,
    },
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleDateSelection = (date: Date, i: number) => {
    // Check if partecipant age is greater than 10

    const now = new Date();

    const differenceMs = now.getTime() - date.getTime();

    if (differenceMs < 315_569_520_000) {
      modals.open({
        title: 'Partecipante minore di 10 anni',
        children:
          'Non è possibile effettuare prenotazioni online con partecipanti minori di 10 anni. Per prenotare questa vacanza contattaci PREMENDO QUI',
      });
    } else {
      form.setFieldValue(`partecipants.${i}.birthDate`, date.toISOString());
    }
  };

  const handleSubmit = (values: { partecipants: CheckoutPartecipant[] }) => {
    dispatch(
      setPartecipants(
        values.partecipants.map((p) => ({
          ...p,
          roomId: null,
        })),
      ),
    );
    onPartecipantChosen();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Text>Inserisci partecipanti</Text>

      <form onSubmit={form.onSubmit(handleSubmit)}>
        {form.getValues().partecipants.map((p, i) => (
          <Group mt="md" align="end" key={p.tempId}>
            <TextInput
              label="Nome"
              required
              key={form.key(`partecipants.${i}.firstName`)}
              {...form.getInputProps(`partecipants.${i}.firstName`)}
            />
            <TextInput
              label="Cognome"
              required
              key={form.key(`partecipants.${i}.lastName`)}
              {...form.getInputProps(`partecipants.${i}.lastName`)}
            />
            <Select
              label="Sesso"
              required
              data={[
                { label: 'Maschio', value: 'male' },
                { label: 'Femmina', value: 'female' },
              ]}
              key={form.key(`partecipants.${i}.gender`)}
              {...form.getInputProps(`partecipants.${i}.gender`)}
            />
            <DateInput
              locale={i18n.language}
              label="Data di nascita"
              required
              key={form.key(`partecipants.${i}.birthDate`)}
              {...form.getInputProps(`partecipants.${i}.birthDate`)}
              value={
                form.getValues().partecipants[i].birthDate
                  ? new Date(form.getValues().partecipants[i].birthDate)
                  : null
              }
              onChange={(value) => {
                if (value) handleDateSelection(value, i);
              }}
            />
            <ActionIcon
              color="red"
              onClick={() => form.removeListItem('partecipants', i)}
            >
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        ))}

        <Button
          mt="lg"
          onClick={() =>
            form.insertListItem('partecipants', {
              firstName: '',
              lastName: '',
              gender: null,
              birthDate: null,
              tempId: randomId(),
            })
          }
        >
          Aggiungi partecipante
        </Button>

        <Button type="submit" display="block" mt="xl">
          Prosegui
        </Button>
      </form>
    </>
  );
}
