import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import 'dayjs/locale/it';

import { useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import { Center, Loader, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import router from '@routes/router';

import theme from './theme';

export default function App() {
  const [isLoading] = useState(false);

  return (
    <MantineProvider theme={theme} forceColorScheme="light">
      <Notifications autoClose={4000} />
      <DatesProvider settings={{ locale: 'it' }}>
        <ModalsProvider
          modalProps={{
            styles: { title: { fontSize: '1.2rem', fontWeight: 'bold' } },
          }}
        >
          {isLoading ? (
            <Center h="100vh">
              <Loader />
            </Center>
          ) : (
            <>
              <RouterProvider router={router} />
            </>
          )}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
}
