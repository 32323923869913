import { createBrowserRouter } from 'react-router-dom';

import DemoCheckout from '@components/demoCheckout/DemoCheckout';

import Checkout from './Checkout';
import NotFound from './NotFound';

const router = createBrowserRouter([
  {
    path: '/',
    element: <DemoCheckout />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
