import { HolidayWithVariations } from '@interfaces/holidays.interface';

import api from './';

export const { useFindHolidayWithVariationsByIdQuery } = api.injectEndpoints({
  endpoints: (build) => ({
    findHolidayWithVariationsById: build.query<HolidayWithVariations, string>({
      query: (id) => ({
        url: `holidays/${id}`,
      }),
      providesTags: ['holidays'],
    }),
  }),
});
