import { Fragment } from 'react';

import {
  ActionIcon,
  Alert,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Group,
  Paper,
  Select,
  Stack,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { randomId, useCounter } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconCheck,
  IconMinus,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';

type RoomType = 'Singola' | 'Doppia';

function getQuantityFromRoomType(type: RoomType) {
  return type === 'Singola' ? 1 : 2;
}

export default function DemoCheckout() {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================
  const [active, { set, increment }] = useCounter(0);

  const [womenPartecipantCount, womenPartecipantCountHandlers] = useCounter(0);
  const [menPartecipantCount, menPartecipantCountHandlers] = useCounter(0);

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================
  const partecipantsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      partecipants: {
        women: [] as { key: string; firstName: string; lastName: string }[],
        men: [] as { key: string; firstName: string; lastName: string }[],
      },
    },
  });

  const roomsForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      rooms: [] as {
        key: string;
        type: RoomType | null;
        partecipants: string[];
      }[],
    },
  });

  const extrasForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      welcome: 0,
      aperitivo: 0,
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Container size="80rem" py="xl" h="100dvh">
      <Stack justify="space-between" h="100%">
        <Stack gap={0} flex={1}>
          <Title>Weekend in barca alle Isole Egadi</Title>
          <Title order={3} c="dimmed">
            12 dicembre - 16 dicembre
          </Title>

          <Stepper
            active={active}
            onStepClick={set}
            allowNextStepsSelect={false}
            mt="xl"
            flex={1}
            styles={{
              root: { display: 'flex', flexDirection: 'column' },
              content: { flexGrow: 1 },
            }}
          >
            <Stepper.Step label="Partecipanti">
              <Paper withBorder p="xl" h="100%">
                <form
                  onSubmit={partecipantsForm.onSubmit(increment)}
                  style={{ height: '100%' }}
                >
                  <Stack h="100%">
                    <Alert color="red" icon={<IconAlertCircle />} fw="bold">
                      Se viaggi con minori di 10 anni contattaci per maggiori
                      informazioni
                    </Alert>

                    <Grid gutter="xl" flex={1} mt="lg">
                      <Grid.Col span={{ md: 6 }}>
                        <Group wrap="nowrap">
                          <Text ta="center" fw="bold" size="lg" mr="lg">
                            Donne
                          </Text>
                          <ActionIcon
                            onClick={() => {
                              womenPartecipantCountHandlers.decrement();
                              partecipantsForm.removeListItem(
                                'partecipants.women',
                                womenPartecipantCount - 1,
                              );
                            }}
                            disabled={womenPartecipantCount === 0}
                          >
                            <IconMinus />
                          </ActionIcon>
                          <Text>{womenPartecipantCount}</Text>
                          <ActionIcon
                            onClick={() => {
                              womenPartecipantCountHandlers.increment();
                              partecipantsForm.insertListItem(
                                'partecipants.women',
                                {
                                  key: randomId(),
                                  firstName: '',
                                  lastName: '',
                                },
                              );
                            }}
                          >
                            <IconPlus />
                          </ActionIcon>
                        </Group>

                        <Stack mt="lg">
                          {partecipantsForm
                            .getValues()
                            .partecipants.women.map((item, index) => (
                              <Group key={item.key}>
                                <TextInput
                                  label="Nome"
                                  placeholder="Nome"
                                  required
                                  key={partecipantsForm.key(
                                    `partecipants.women.${index}.firstName`,
                                  )}
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.firstName`,
                                  )}
                                  flex={1}
                                />
                                <TextInput
                                  label="Cognome"
                                  placeholder="Cognome"
                                  required
                                  key={partecipantsForm.key(
                                    `partecipants.women.${index}.lastName`,
                                  )}
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.lastName`,
                                  )}
                                  flex={1}
                                />
                              </Group>
                            ))}
                        </Stack>
                      </Grid.Col>
                      <Grid.Col span={{ md: 6 }}>
                        <Group wrap="nowrap">
                          <Text ta="center" fw="bold" size="lg" mr="lg">
                            Uomini
                          </Text>
                          <ActionIcon
                            onClick={() => {
                              menPartecipantCountHandlers.decrement();
                              partecipantsForm.removeListItem(
                                'partecipants.men',
                                menPartecipantCount - 1,
                              );
                            }}
                            disabled={menPartecipantCount === 0}
                          >
                            <IconMinus />
                          </ActionIcon>
                          <Text>{menPartecipantCount}</Text>
                          <ActionIcon
                            onClick={() => {
                              menPartecipantCountHandlers.increment();
                              partecipantsForm.insertListItem(
                                'partecipants.men',
                                {
                                  key: randomId(),
                                  firstName: '',
                                  lastName: '',
                                },
                              );
                            }}
                          >
                            <IconPlus />
                          </ActionIcon>
                        </Group>

                        <Stack mt="lg">
                          {partecipantsForm
                            .getValues()
                            .partecipants.men.map((item, index) => (
                              <Group key={item.key}>
                                <TextInput
                                  label="Nome"
                                  placeholder="Nome"
                                  required
                                  key={partecipantsForm.key(
                                    `partecipants.men.${index}.firstName`,
                                  )}
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.firstName`,
                                  )}
                                  flex={1}
                                />
                                <TextInput
                                  label="Cognome"
                                  placeholder="Cognome"
                                  required
                                  key={partecipantsForm.key(
                                    `partecipants.men.${index}.lastName`,
                                  )}
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.lastName`,
                                  )}
                                  flex={1}
                                />
                              </Group>
                            ))}
                        </Stack>
                      </Grid.Col>
                    </Grid>

                    <Group justify="end" align="end">
                      {womenPartecipantCount + menPartecipantCount === 0 && (
                        <Alert color="red">
                          Seleziona almeno un partecipante per continuare
                        </Alert>
                      )}
                      <Button
                        size="lg"
                        disabled={
                          womenPartecipantCount + menPartecipantCount === 0
                        }
                        type="submit"
                      >
                        PROSEGUI
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Camere">
              <Paper withBorder p="xl" h="100%">
                <form
                  onSubmit={roomsForm.onSubmit(increment)}
                  style={{ height: '100%' }}
                >
                  <Stack h="100%">
                    <Group justify="space-between" align="start" h="100%">
                      <Stack flex={1} align="start">
                        <Button
                          leftSection={<IconPlus />}
                          onClick={() => {
                            roomsForm.insertListItem('rooms', {
                              key: randomId(),
                              type: null,
                              partecipants: [],
                            });
                          }}
                        >
                          Aggiungi camera
                        </Button>

                        {roomsForm.getValues().rooms.map((room, index) => (
                          <Group key={room.key} gap="xl">
                            <Stack>
                              <Select
                                label="Tipologia camera"
                                placeholder="Seleziona tipologia"
                                data={['Singola', 'Doppia']}
                                required
                                allowDeselect={false}
                                key={roomsForm.key(`rooms.${index}.type`)}
                                {...roomsForm.getInputProps(
                                  `rooms.${index}.type`,
                                )}
                                onChange={(value) => {
                                  roomsForm.setFieldValue(
                                    `rooms.${index}.partecipants`,
                                    [],
                                  );
                                  roomsForm.setFieldValue(
                                    `rooms.${index}.type`,
                                    value,
                                  );
                                }}
                              />
                              <Button
                                color="red"
                                leftSection={<IconTrash />}
                                onClick={() => {
                                  roomsForm.removeListItem('rooms', index);
                                }}
                              >
                                Rimuovi camera
                              </Button>
                            </Stack>

                            {room.type && (
                              <Stack gap="xs">
                                <Text>
                                  Seleziona {getQuantityFromRoomType(room.type)}{' '}
                                  person
                                  {getQuantityFromRoomType(room.type) > 1
                                    ? 'e'
                                    : 'a'}
                                </Text>
                                {[
                                  ...partecipantsForm.getValues().partecipants
                                    .women,
                                  ...partecipantsForm.getValues().partecipants
                                    .men,
                                ].map((partecipant) => (
                                  <Chip
                                    key={room.key + partecipant.key}
                                    checked={room.partecipants.includes(
                                      partecipant.key,
                                    )}
                                    onChange={(checked) => {
                                      if (checked) {
                                        roomsForm.insertListItem(
                                          `rooms.${index}.partecipants`,
                                          partecipant.key,
                                        );
                                      } else {
                                        roomsForm.removeListItem(
                                          `rooms.${index}.partecipants`,
                                          room.partecipants.indexOf(
                                            partecipant.key,
                                          ),
                                        );
                                      }
                                    }}
                                    disabled={
                                      (!room.partecipants.includes(
                                        partecipant.key,
                                      ) &&
                                        room.partecipants.length >=
                                          getQuantityFromRoomType(
                                            room.type!,
                                          )) ||
                                      !!roomsForm
                                        .getValues()
                                        .rooms.find(
                                          (r) =>
                                            r.key !== room.key &&
                                            r.partecipants.includes(
                                              partecipant.key,
                                            ),
                                        )
                                    }
                                  >
                                    {partecipant.firstName}{' '}
                                    {partecipant.lastName}
                                  </Chip>
                                ))}
                              </Stack>
                            )}
                          </Group>
                        ))}
                      </Stack>

                      <Stack>
                        <Text>
                          Partecipanti assegnati{' '}
                          {roomsForm
                            .getValues()
                            .rooms.reduce(
                              (acc, room) => acc + room.partecipants.length,
                              0,
                            )}{' '}
                          / {womenPartecipantCount + menPartecipantCount}
                        </Text>
                      </Stack>
                    </Group>

                    <Group justify="end" align="end">
                      {roomsForm.getValues().rooms.length === 0 ? (
                        <Alert color="red">
                          Aggiungi almeno una camera per continuare
                        </Alert>
                      ) : (
                        roomsForm
                          .getValues()
                          .rooms.reduce(
                            (acc, room) => acc + room.partecipants.length,
                            0,
                          ) !==
                          womenPartecipantCount + menPartecipantCount && (
                          <Alert color="red">
                            Tutti i partecipanti devono essere assegnati a una
                            camera
                          </Alert>
                        )
                      )}

                      <Button
                        size="lg"
                        disabled={
                          roomsForm.getValues().rooms.length === 0 ||
                          roomsForm
                            .getValues()
                            .rooms.reduce(
                              (acc, room) => acc + room.partecipants.length,
                              0,
                            ) !==
                            womenPartecipantCount + menPartecipantCount
                        }
                        type="submit"
                      >
                        PROSEGUI
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Extra">
              <Paper withBorder p="xl" h="100%">
                <form
                  onSubmit={extrasForm.onSubmit(increment)}
                  style={{ height: '100%' }}
                >
                  <Stack h="100%">
                    <Stack flex={1}>
                      <Title order={3}>Extra disponibili</Title>

                      <Paper withBorder p="md">
                        <Group justify="space-between">
                          <Text>Regalo di benvenuto</Text>
                          <Group>
                            <Text>€ 20.00</Text>
                            <Button
                              onClick={() => {
                                if (extrasForm.getValues().welcome === 1) {
                                  extrasForm.setFieldValue('welcome', 0);
                                } else {
                                  extrasForm.setFieldValue('welcome', 1);
                                }
                              }}
                              leftSection={
                                extrasForm.getValues().welcome === 1 ? (
                                  <IconCheck />
                                ) : undefined
                              }
                              color={
                                extrasForm.getValues().welcome === 1
                                  ? 'green'
                                  : undefined
                              }
                            >
                              {extrasForm.getValues().welcome === 1
                                ? 'Selezionato'
                                : 'Seleziona'}
                            </Button>
                          </Group>
                        </Group>
                      </Paper>
                      <Paper withBorder p="md">
                        <Group justify="space-between">
                          <Text>Aperitivo (1 persona)</Text>
                          <Group>
                            <Text>€ 10.00</Text>
                            <Text fs="italic" size="sm" c="dimmed">
                              / persona
                            </Text>
                            <ActionIcon
                              disabled={extrasForm.getValues().aperitivo === 0}
                              onClick={() => {
                                extrasForm.setFieldValue(
                                  'aperitivo',
                                  extrasForm.getValues().aperitivo - 1,
                                );
                              }}
                            >
                              <IconMinus />
                            </ActionIcon>
                            <Text>{extrasForm.getValues().aperitivo}</Text>
                            <ActionIcon
                              onClick={() => {
                                extrasForm.setFieldValue(
                                  'aperitivo',
                                  extrasForm.getValues().aperitivo + 1,
                                );
                              }}
                              disabled={
                                extrasForm.getValues().aperitivo ===
                                partecipantsForm.getValues().partecipants.women
                                  .length +
                                  partecipantsForm.getValues().partecipants.men
                                    .length
                              }
                            >
                              <IconPlus />
                            </ActionIcon>
                          </Group>
                        </Group>
                      </Paper>

                      <Title order={3}>Assicurazioni</Title>
                      <Text>TODO</Text>
                    </Stack>

                    <Group justify="end" align="end">
                      <Button size="lg" type="submit">
                        PROSEGUI
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Dettagli partecipanti">
              <Paper withBorder p="xl" h="100%">
                <form onSubmit={increment} style={{ height: '100%' }}>
                  <Stack h="100%">
                    <Stack flex={1}>
                      <Title order={3}>Dati partecipanti</Title>

                      <Alert>
                        <Group justify="space-between">
                          Non hai i dati di tutti i partecipanti? Puoi inserirli
                          successivamente, entro 24h dalla prenotazione
                          <Button variant="outline">
                            Inserisci successivamente
                          </Button>
                        </Group>
                      </Alert>

                      {partecipantsForm
                        .getValues()
                        .partecipants.women.map((partecipant, index) => (
                          <Fragment key={partecipant.key}>
                            <Divider
                              label={`${partecipant.firstName} ${partecipant.lastName} (femmina)`}
                              labelPosition="left"
                              fw="bold"
                              styles={{
                                label: {
                                  fontSize: '1.1rem',
                                  color: 'var(--mantine-color-black)',
                                },
                              }}
                            />

                            <Grid>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Codice fiscale"
                                  placeholder="Codice fiscale"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.cf`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Data di nascita"
                                  placeholder="Data di nascita"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.birthDate`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Città di nascita"
                                  placeholder="Città di nascita"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.birthCity`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Nazione"
                                  placeholder="Nazione"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.country`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Provincia"
                                  placeholder="Provincia"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.province`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Città"
                                  placeholder="Città"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.city`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="CAP"
                                  placeholder="CAP"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.cap`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Indirizzo"
                                  placeholder="Indirizzo"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.address`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Textarea
                                  label="Note / allergie / altre informazioni"
                                  placeholder="Note / allergie / altre informazioni"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.women.${index}.notes`,
                                  )}
                                />
                              </Grid.Col>
                            </Grid>
                          </Fragment>
                        ))}
                      {partecipantsForm
                        .getValues()
                        .partecipants.men.map((partecipant, index) => (
                          <Fragment key={partecipant.key}>
                            <Divider
                              mt="lg"
                              label={`${partecipant.firstName} ${partecipant.lastName} (maschio)`}
                              labelPosition="left"
                              fw="bold"
                              styles={{
                                label: {
                                  fontSize: '1.1rem',
                                  color: 'var(--mantine-color-black)',
                                },
                              }}
                            />

                            <Grid>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Codice fiscale"
                                  placeholder="Codice fiscale"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.cf`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Data di nascita"
                                  placeholder="Data di nascita"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.birthDate`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Città di nascita"
                                  placeholder="Città di nascita"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.birthCity`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Nazione"
                                  placeholder="Nazione"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.country`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Provincia"
                                  placeholder="Provincia"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.province`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="Città"
                                  placeholder="Città"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.city`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={{ md: 4 }}>
                                <TextInput
                                  label="CAP"
                                  placeholder="CAP"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.cap`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={{ md: 4 }}>
                                <DateInput
                                  label="Indirizzo"
                                  placeholder="Indirizzo"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.address`,
                                  )}
                                />
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Textarea
                                  label="Note / allergie / altre informazioni"
                                  placeholder="Note / allergie / altre informazioni"
                                  {...partecipantsForm.getInputProps(
                                    `partecipants.men.${index}.notes`,
                                  )}
                                />
                              </Grid.Col>
                            </Grid>
                          </Fragment>
                        ))}
                    </Stack>

                    <Group justify="end" align="end">
                      <Button size="lg" type="submit">
                        PROSEGUI
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Paper>
            </Stepper.Step>
            <Stepper.Step label="Pagamento">
              <Paper withBorder p="xl" h="100%">
                <Text fw="bold">TODO</Text>
                <Text>Riepilogo completo</Text>
                <Text>Inserimento coupon</Text>
                <Text>Accesso / registrazione obbligatoria</Text>
                <Text>Pagamento</Text>
              </Paper>
            </Stepper.Step>
          </Stepper>
        </Stack>

        <Paper bg="brand" c="white" p="lg">
          <Group justify="space-between">
            <Stack gap={0}>
              <Text>
                Partecipanti: {womenPartecipantCount + menPartecipantCount}
              </Text>
              {active >= 1 && (
                <Text>Camere: {roomsForm.getValues().rooms.length}</Text>
              )}
            </Stack>

            <Group justify="end" align="center">
              <Text>TOTALE</Text>
              <Text fw="bold" size="xl">
                € 250.00
              </Text>
            </Group>
          </Group>
        </Paper>
      </Stack>
    </Container>
  );
}
