import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';
import {
  HolidayVariation,
  HolidayWithVariations,
} from '@interfaces/holidays.interface';

import { RootState } from '../store';

interface CheckoutState {
  holiday: HolidayWithVariations | null;
  holidayVariation: HolidayVariation | null;
  partecipants: CheckoutPartecipant[];
}

const initialState: CheckoutState = {
  holiday: null,
  holidayVariation: null,
  partecipants: [],
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setHoliday(state, action: PayloadAction<HolidayWithVariations | null>) {
      state.holiday = action.payload;
    },
    setHolidayVariation(state, action: PayloadAction<HolidayVariation | null>) {
      state.holidayVariation = action.payload;
    },
    setPartecipants(state, action: PayloadAction<CheckoutPartecipant[]>) {
      state.partecipants = action.payload;
    },
    setPartecipantRoomId(
      state,
      action: PayloadAction<{ id: string; roomId: string | null }>,
    ) {
      state.partecipants.find((p) => p.tempId === action.payload.id)!.roomId =
        action.payload.roomId;
    },
  },
});

export const {
  setHoliday,
  setHolidayVariation,
  setPartecipants,
  setPartecipantRoomId,
} = slice.actions;
export const selectHoliday = (state: RootState) => state.checkout.holiday;
export const selectHolidayVariation = (state: RootState) =>
  state.checkout.holidayVariation;
export const selectPartecipants = (state: RootState) =>
  state.checkout.partecipants;

export default slice.reducer;
