import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Divider, Loader, Stepper, Text, Title } from '@mantine/core';
import { useCounter } from '@mantine/hooks';

import { useFindHolidayWithVariationsByIdQuery } from '@api/holidays.api';

import { setHoliday } from '@slices/checkout.slice';

import HolidayVariationSelector from '@components/checkout/HolidayVariationSelector';
import PartecipantsSelector from '@components/checkout/PartecipantsSelector';
import RoomsSelector from '@components/checkout/RoomsSelector';

export default function Checkout() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const holidayId = useMemo(() => searchParams.get('h'), [searchParams]);

  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const [step, stepHandler] = useCounter(0);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: holiday,
    isLoading,
    error,
  } = useFindHolidayWithVariationsByIdQuery(holidayId!, {
    skip: holidayId === null,
  });

  useEffect(() => {
    dispatch(setHoliday(holiday ?? null));
  }, [holiday]);

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Title>Checkout</Title>
      {isLoading ? (
        <Loader />
      ) : holidayId === null || !holiday || error ? (
        <Text>Nessuna vacanza trovata</Text>
      ) : (
        <>
          <Divider my="md" />
          <Title order={3} mb="sm">
            {holiday.name}
          </Title>

          <Stepper
            active={step}
            onStepClick={stepHandler.set}
            allowNextStepsSelect={false}
          >
            <Stepper.Step label="Scelta data">
              <HolidayVariationSelector
                onVariationChosen={stepHandler.increment}
              />
            </Stepper.Step>
            <Stepper.Step label="Partecipanti">
              <PartecipantsSelector
                onPartecipantChosen={stepHandler.increment}
              />
            </Stepper.Step>
            <Stepper.Step label="Camere">
              <RoomsSelector onRoomsChosen={stepHandler.increment} />
            </Stepper.Step>
            <Stepper.Completed>Fine!</Stepper.Completed>
          </Stepper>
        </>
      )}
    </>
  );
}
