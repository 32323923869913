import { Mutex } from 'async-mutex';

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import env from '@config/env';

export function isApiError(
  error: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): error is FetchBaseQueryError & { data: any } {
  return typeof error === 'object' && error != null && 'status' in error;
}

const BASE_URL = env.API_URL;
const LOGIN_PATH = env.API_LOGIN_PATH;
const REFRESH_PATH = env.API_REFRESH_PATH;

// Create a new mutex
const mutex = new Mutex();

const baseQueryFn = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});

const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // Wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await baseQueryFn(args, api, extraOptions);

  if (
    args.url !== LOGIN_PATH &&
    args.url !== REFRESH_PATH &&
    result.error?.status === 401
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        // Try refresh token
        const refreshResult = await baseQueryFn(
          { url: REFRESH_PATH, method: 'POST' },
          api,
          extraOptions,
        );

        if (refreshResult.error) {
          // api.dispatch(logout());

          return result;
        } else {
          // const user = refreshResult.data as User;

          // api.dispatch(setCredentials({ user }));

          // Retry the initial query
          result = await baseQueryFn(args, api, extraOptions);
        }
      } finally {
        // Release must be called once the mutex should be released again.
        release();
      }
    } else {
      // Wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQueryFn(args, api, extraOptions);
    }
  }

  return result;
};

const api = createApi({
  baseQuery,
  tagTypes: ['holidays', 'holidaysVariationsRooms'], // Define here tags for cache invalidation
  endpoints: () => ({}),
});

export default api;
