import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';
import it from './translations/it.json';

export const defaultNS = 'translation';
export const resources = {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;
