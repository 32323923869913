import { Loader, MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Plus Jakarta Sans',
  headings: {
    fontFamily: 'Plus Jakarta Sans',
  },

  primaryColor: 'brand',

  colors: {
    brand: [
      '#ebf3ff',
      '#d3e3fa',
      '#a2c6f7',
      '#70a6f6',
      '#498cf5',
      '#367bf5',
      '#2c73f6',
      '#2161dc',
      '#1756c5',
      '#004aad',
    ],
  },

  defaultRadius: 'lg',

  components: {
    Loader: Loader.extend({
      defaultProps: {
        variant: 'bars',
      },
    }),
  },
};

export default theme;
