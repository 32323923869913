import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Divider, Group, Loader, Select, Text } from '@mantine/core';

import { CheckoutPartecipant } from '@interfaces/checkout.interface';
import { Gender } from '@interfaces/common.interface';
import { RoomType } from '@interfaces/holidays.interface';

import { useFindAllRoomsQuery } from '@api/holidaysVariationsRooms.api';

import {
  selectHoliday,
  selectHolidayVariation,
  selectPartecipants,
  setPartecipantRoomId,
} from '@slices/checkout.slice';

interface RoomsSelectorProps {
  onRoomsChosen: () => void;
}

export default function RoomsSelector({ onRoomsChosen }: RoomsSelectorProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const holiday = useSelector(selectHoliday);
  const holidayVariation = useSelector(selectHolidayVariation);
  const partecipants = useSelector(selectPartecipants);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: rooms = [],
    isLoading,
    error,
  } = useFindAllRoomsQuery({
    holidayId: holiday!.id,
    variationId: holidayVariation!.id,
  });

  const mappedSelectableRooms = useMemo(() => {
    const r: Partial<
      Record<RoomType, { roomId: string; sharedWith: Gender | null }[]>
    > = {};

    for (const room of rooms) {
      for (const type of room.types) {
        if (!r[type.type]) {
          r[type.type] = [{ roomId: room.id, sharedWith: room.sharedWith }];
        } else {
          r[type.type]!.push({ roomId: room.id, sharedWith: room.sharedWith });
        }
      }
    }

    return r;
  }, [rooms]);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleRoomSelection = (
    partecipant: CheckoutPartecipant,
    type: RoomType | null,
  ) => {
    if (!type) {
      dispatch(setPartecipantRoomId({ id: partecipant.tempId, roomId: null }));
    } else {
      // TODO: find roomId based on type
      dispatch(setPartecipantRoomId({ id: partecipant.tempId, roomId: null }));

      // TODO: sono arrivato qui. capire come funziona la selezione e l'assegnamento delle stanze
    }
  };

  const handleSubmit = () => {
    onRoomsChosen();
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return isLoading ? (
    <Loader />
  ) : rooms.length === 0 || error ? (
    <Text>Nessuna stanza trovata</Text>
  ) : (
    <>
      <Text>Seleziona stanze</Text>
      <Text>
        Partecipanti assegnati {partecipants.filter((p) => p.roomId).length} su{' '}
        {partecipants.length}
      </Text>

      <Divider my="md" />

      {partecipants.map((p, i) => (
        <Group key={i}>
          <Text>{p.firstName}</Text>
          <Select
            key={`room_select_${i}`}
            placeholder="Seleziona camera"
            data={Object.entries(mappedSelectableRooms)
              .filter(([, rooms]) =>
                rooms.find((r) => !r.sharedWith || r.sharedWith === p.gender),
              )
              .map(([type]) => ({
                value: type,
                label: type,
              }))}
            onChange={(value) =>
              handleRoomSelection(p, value as RoomType | null)
            }
          />
        </Group>
      ))}

      <Divider my="md" />

      <Button display="block" mt="xl" onClick={handleSubmit}>
        Prosegui
      </Button>
    </>
  );
}
