import { HolidayVariationRoom } from '@interfaces/holidays.interface';

import api from './';

export const { useFindAllRoomsQuery } = api.injectEndpoints({
  endpoints: (build) => ({
    findAllRooms: build.query<
      HolidayVariationRoom[],
      { holidayId: string; variationId: string }
    >({
      query: ({ holidayId, variationId }) => ({
        url: `holidays/${holidayId}/variations/${variationId}/rooms`,
      }),
      providesTags: ['holidaysVariationsRooms'],
    }),
  }),
});
